import { MdClose } from 'react-icons/md';
import { useEffect, useState } from 'react';
import { LoaderContainer, ModalContainer, Overlay, Title, SubTitle, ChildrenContainer, LoaderElt } from '@components/modal/Modal_.style';

export interface ModalProps {
  show?: boolean;
  title?: string;
  subTitle?: string;
  onClose: any;
  children?: React.ReactNode;
  actions?: Array<{ label: string; onClick?: () => void; icon?: any; selected?: boolean }>;
  size?: { height?: string; width?: string; maxHeight?: string };
  alignment?: string;
  loading?: boolean;
  overAll?: boolean;
  hideClose?: boolean;
  closeColor?: string;
  rowGap?: string;
  top?: string;
}

export const Modal = ({ show, onClose, title, children, size, alignment, loading, overAll, subTitle, hideClose, closeColor, rowGap, top }: ModalProps) => {
  const [displayModal, setDisplayModal] = useState(false);

  const handleOnClose = () => {
    onClose && onClose();
    return;
  };

  useEffect(() => {
    if (show) {
      setDisplayModal(show);
    } else {
      let timer = setTimeout(() => {
        setDisplayModal(show);
      }, 400);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [show]);

  return (
    <>
      {displayModal && (
        <>
          <ModalContainer
            closing={!show}
            alignment={alignment}
            height={size?.height}
            width={size?.width}
            maxHeight={size?.maxHeight}
            overAll={overAll}
            top={top}>
            {loading ? (
              <LoaderContainer>
                <LoaderElt />
              </LoaderContainer>
            ) : (
              <>
                {title && <Title dangerouslySetInnerHTML={{ __html: title }} />}
                {subTitle && <SubTitle dangerouslySetInnerHTML={{ __html: subTitle }} />}
                {!hideClose && <MdClose color={closeColor} onClick={handleOnClose} />}
                <ChildrenContainer alignment={alignment} rowGap={rowGap}>{children}</ChildrenContainer>
              </>
            )}
          </ModalContainer>
          <Overlay closing={!show} onClick={() => !hideClose && handleOnClose()} overAll={overAll} />
        </>
      )}
    </>
  );
};
