import styled, { css } from 'styled-components';

export const Content = styled.div<{ applyPadding?: boolean; customPadding?: string, tabsUnderlined?: boolean, position?: string }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${({ applyPadding, customPadding }) =>
    applyPadding &&
    css`
      padding: ${customPadding ? customPadding : '3rem'};
    `}
  position: ${({ position }) => position};
`;

export const PageTitle = styled.h1`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;
  color: black;
  svg {
    margin-left: 1rem;
    transition: transform 0.3s ease;
  }
`;
