import { saveAs } from 'file-saver';

import { Status } from './AuditsAndInventory.types';

export const sortSubcontractorsByStatus = data => {
  const statusOrder: Record<Status, number> = {
    conforme: 0,
    ongoing: 1,
    non_conforme: 2,
  };

  return [...data].sort((a, b) => statusOrder[a.status] - statusOrder[b.status]);
};

export const formatDate = () => {
  return new Date().toLocaleDateString('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export const createAndSaveInventoryBlob = (buffer: ArrayBuffer, fileName: string) => {
  const blob = new Blob([new Uint8Array(buffer)], { type: 'application/octet-stream' });
  saveAs(blob, `${fileName}${formatDate()}.xlsx`);
};
