import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { TbUser } from 'react-icons/tb';

import { selectAllAccounts, selectSelectedAccount } from 'src/reducers/account.reducer';
import { selectUserActions } from 'src/reducers/contentful.reducer';

import { EntityProps, ViewProps } from '@components-new/common/types';
import { Container } from '@components-new/atoms';
import { PageWrapper } from '@components-new/molecules';

import Inventory from './inventory/Inventory';
import Audits from './audit/Audit';

import { addEventTracking, getEntityActionsMerged } from '@utils/utils';
import { getAccountById } from '@utils/api';

import { ViewComponents } from './AuditsAndInventory.types';
import { StyledInputComponent } from './AuditsAndinventory_.styles';

const AuditsAndInventory = () => {
  const { t } = useTranslation();

  const allAccounts = useSelector(selectAllAccounts);
  const selectedAccount = useSelector(selectSelectedAccount);
  const userActions = useSelector(selectUserActions);

  const [selectedEntity, setSelectedEntity] = useState<EntityProps>(selectedAccount.entities.length === 1 ? selectedAccount.entities[0] : null);
  const [currentAccount, setCurrentAccount] = useState<any>(selectedAccount);
  const [actionPlanActived, setActionPlanActived] = useState<boolean>(false);
  const [view, setView] = useState<ViewProps>({
    label: t('views.inventory'),
    value: 'inventory',
  });

  useEffect(() => {
    changeCurrentAccount(selectedAccount)
  }, [])

  useEffect(() => {
    if (!actionPlanActived) {
      setView({
        label: t('views.inventory'),
        value: 'inventory',
      });
    }
  }, [actionPlanActived]);


  const views = useMemo(() => {
    return [
      {
        label: t('views.inventory'),
        value: 'inventory',
        onClick: () => {
          setView({
            label: t('views.inventory'),
            value: 'inventory',
          });
        },
      },
      actionPlanActived && currentAccount._id !== 'all' && currentAccount._id !== 'myClients' && {
        label: t('views.audits'),
        value: 'audits',
        onClick: () => {
          addEventTracking('audits', 'Click');
          setView({ label: t('views.audits'), value: 'audits' });
        },
      },
    ];
  }, [actionPlanActived, currentAccount]);

  const viewComponents: ViewComponents = {
    inventory: (
      <Inventory
        currentAccount={currentAccount}
        selectedEntity={selectedEntity}
        actionPlanActived={actionPlanActived}
      />
    ),
    audits: (
      <Audits
        currentAccount={currentAccount}
        selectedEntity={selectedEntity}
      />
    )
  };

  const SelectAll = () => {
    return (
      <Container flex alignItems='center' gap='5px'>
        <div>{t('common.viewAllClient')}</div>
        <Container flex alignItems='center' mrChild='-3px'>
          <TbUser size={12} color='#3D3D3D' /><TbUser size={12} color='#3D3D3D' /><TbUser size={12} color='#3D3D3D' />
        </Container>
      </Container>)
  }

  const getAccountsOptions = () => {
    let accounts = []
    if (view.value === 'inventory') {
      accounts = [{
        "_id": "all",
        "companyName": t('common.viewAllClient'),
      },
      {
        "_id": "myClients",
        "companyName": t('common.myClients'),
      },
      ...allAccounts]
    }
    else {
      accounts = allAccounts
    }

    return accounts?.map(account => ({
      label: account._id === 'all' && <SelectAll /> || account.companyName,
      value: account,
    }));
  };


  const getSelectedAccount = () => {
    if (currentAccount) {
      return { label: currentAccount.companyName, value: currentAccount };
    } else if (getAccountsOptions().length === 1) {
      return getAccountsOptions()[0];
    }
    return null;
  };

  const changeCurrentAccount = async (account: any) => {
    let fullAccount;
    if (account._id === 'all' || account._id === 'myClients') {
      fullAccount = account
      setActionPlanActived(true)
    }

    else {
      if (account?.entities) {
        fullAccount = account
      }
      else {
        fullAccount = await getAccountById(account._id)
      }
      if (fullAccount?.entities?.length) {
        const hasActionAudits = await Promise.all(
          fullAccount.entities.map(async (entity) => {
            const fullActions = await getEntityActionsMerged(entity?.actionPlan.actions, userActions);
            return fullActions.some(
              (action) => action.category === 'Audit' && action.active === true
            );
          })
        ).then(results => results.some(Boolean));
        setActionPlanActived(hasActionAudits);
      }
      else {
        setActionPlanActived(false)
      }
    }

    if (fullAccount?.entities?.length === 1) {
      setSelectedEntity(fullAccount.entities[0])
    }
    else {
      setSelectedEntity(null)
    }
    setCurrentAccount(fullAccount)
  }

  const getEntitiesOptions = () => {
    return currentAccount?.entities?.map(entity => ({
      label: entity.name,
      value: entity,
    }));
  };

  const getSelectedEntity = () => {
    if (selectedEntity) {
      return { label: selectedEntity.name, value: selectedEntity };
    } else if (getEntitiesOptions()?.length === 1) {
      return getEntitiesOptions()[0];
    }
    return null;
  };

  return (
    <PageWrapper customPadding='30px' selectedView={view} views={views} applyPadding tabsUnderlined position={'relative'}>
      <Container flex mb='1rem' mrChild='32px' height='40px'>
        <StyledInputComponent
          type={'select'}
          options={getAccountsOptions()}
          onChange={(_, value) => changeCurrentAccount(value)}
          sharable={false}
          defaultValue={getSelectedAccount()}
          roundedBorder='17px'
          fontFamily='Nunito'
        />
        {currentAccount?._id !== 'all' && currentAccount._id !== 'myClients' && <StyledInputComponent
          placeholder={t('actionPlan.choose_entity')}
          type={'select'}
          options={getEntitiesOptions()}
          onChange={(_, value) => setSelectedEntity(value)}
          value={getSelectedEntity()}
          sharable={false}
          defaultValue={getSelectedEntity()}
          noOptionsMessage={() => t('actionPlan.no_finality_available')}
          roundedBorder='17px'
          fontFamily='Nunito'
          clearable='true'
        />}
      </Container>

      {viewComponents[view.value as keyof ViewComponents] || null}
    </PageWrapper>

  );
};

export default AuditsAndInventory;

