import { COLORS } from '@utils/constants';
import { MdOutlineLightbulb } from 'react-icons/md';
import styled, { css } from 'styled-components';

const inputWidth = '100%';

export const UploadItemName = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 184px;
  overflow: hidden;
`;

export const InfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3px;
`;

export const UploadItemContainer = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  width: 100%;

  svg {
    width: 40px;
    height: 40px;
  }
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000070;
`;

export const DeleteDocument = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${COLORS.MediumGrey};
  color: ${COLORS.DarkGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 1;
  }

  svg {
    width: 30px;
    height: 30px;
  }
`;

export const UploadContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  span:not(.infos) {
    font-size: 1.4rem;
    font-weight: 500;
  }

  .infos {
    font-size: 1.125rem;
  }
`;

export const UploadInputContainer = styled.div<{ filled?: string}>`
  width: calc(100% - 20px);
  height: 100%;
  color: ${({ filled }) => {
    let color = COLORS.DarkGrey;
    if (filled) {
    return color;
  }
  }};
  background-color: ${({ filled }) => {
    let color = 'transparent';
    if (filled) {
      color = COLORS.xlsx + 10;
    }
    return color;
  }};

  border: 1px dashed
    ${({ filled }) => {
      let color = COLORS.MediumGrey;
      if (filled) {
        color = COLORS.xlsx;
      }
      return color;
    }};

  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  position: relative;
`;

export const Error = styled.div<{ errorColor?: string }>`
  // color: ${COLORS.softRed};
  color: ${({ errorColor }) => (errorColor ? errorColor : COLORS.softRed)};
  margin-left: 5px;
  margin-top: 5px;
  font-size: 1.125rem;
`;

export const UploadError = styled.div<{ errorColor?: string }>`
  color: ${({ errorColor }) => (errorColor ? errorColor : COLORS.softRed)};
  margin-top: 5px;
  font-size: 1.125rem;
`;

export const PasswordContainer = styled.div`
  position: relative;

  i {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const InputStatus = styled.div<{ filled: boolean }>`
  background-color: ${({ filled }) => (filled ? COLORS.DarkGreen : COLORS.LightGrey)};
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;

  svg {
    color: white;
  }
`;

export const DisabledList = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  span {
    border-radius: 5px;
    border: 1px solid gray;
    padding: 5px 10px;
    user-select: none;
  }
`;

export const StyledInput = styled.input<{ disabledInput: boolean }>`
  font-size: 1.3rem;
  ${({ disabledInput }) => disabledInput && `user-select: none !Important;`}

  &:focus-visible {
    outline-offset: unset;
    outline: 1px solid #2684ff;
  }
`;

export const StyledHelpIcon = styled(MdOutlineLightbulb)<{ sx: any }>`
  color: ${COLORS.Squash};
  min-width: 20px;
  min-height: 20px;

  &:focus {
    outline: none;
  }
`;

export const FieldContainer = styled.div<{
  last: boolean;
  span: number;
  display: string;
  disabled: boolean;
  subQuestion: boolean;
  filled: boolean;
  locked: boolean;
  fontFamily: string;
  roundedBorder: string;
  height: string;
  inputFontSize: string
}>`
    display: ${({ display }) => (!display || display === 'true' ? 'block' : 'none')};
    width: ${({ subQuestion }) => (subQuestion ? 'calc(100% - 50px)' : '100%')};
    ${({ subQuestion }) =>
      subQuestion &&
      css`
        margin-left: 50px;

        &:before {
          content: '';
          position: absolute;
          top: 20px;
          left: -40px;
          bottom: 0;
          width: 20px;
          transform: translateY(-50%);
          border-bottom-left-radius: 10px;
          border-left: 2px solid #c8c8c8;
          border-bottom: 2px solid #c8c8c8;
        }
      `}
    font-family: 'Baloo 2';
        /*grid-column: span ${({ span }) => (span ? span : 2)} / auto;*/
    background-color: transparent;
    position: relative;

    ${({ locked }) =>
      locked &&
      css`
        border-radius: 5px;
        pointer-events: none;
        background-color: ${COLORS.LightGrey};
        cursor: not-allowed;
        padding: 10px;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 5px;
          opacity: 0.5;
        }
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        border-radius: 5px;
        pointer-events: none;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 5px;
          opacity: 0.5;
        }
      `}
    ${({ last }) =>
      last &&
      `
    padding-bottom: 30px;
  `}
    .input {
        display: flex;
        flex-direction: column;
        font-size: ${({ inputFontSize }) => (inputFontSize || '1.4rem')};
        font-family: ${({ fontFamily }) => (fontFamily && `'${fontFamily}'`)};

        label {
        }

        input[type='text'],
        input[type='password'] {
            width: calc(${inputWidth} - 14px);
            height: ${({ height }) => (height || '40px')};
            border: solid 1px hsl(0, 0%, 80%);
            border-radius: ${({ roundedBorder }) => (roundedBorder || '4px')};
            font-family: ${({ fontFamily }) => (fontFamily ? `'${fontFamily}'` : `'Open Sans'`)};
            font-size: 1.3rem;
        }
    }

    > * {
        z-index: 9999;
    }

    ${({ subQuestion, filled }) =>
      subQuestion &&
      !filled &&
      css`
        animation: backInRight 0.3s ease-in-out;
        
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: -50px;
            width: calc(50px + 100%);
            height: 100%;
            padding: 20px 10px;
            border-radius: 5px;
            background-color: #326c8250;
            pointer-events: none;
            transform: translateY(-50%);
            animation: fadeOut 0.5s forwards;
            z-index: 9;

    `}
}
`;

export const RadioContainer = styled.div<{ dark: boolean; disabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 20px;
  margin-top: 10px;
  width: ${inputWidth};
  flex-wrap: wrap;
  row-gap: 15px;

  &.long-answers {
    flex-direction: column;
    row-gap: 15px;

    label {
      display: flex;
      align-items: center;
      width: ${inputWidth};
      column-gap: 10px;
    }

    input {
      display: block;

      &:checked + span {
        background-color: transparent;
        color: #187ead;
      }
    }

    span {
      width: auto;
      height: auto;
      background-color: transparent;
      padding: 0;
      border-radius: 0;
      box-shadow: none;
      color: ${({ dark }) => (dark ? '#f1f2f6' : 'black')};

      &:hover {
        background-color: transparent;
        color: ${({ dark }) => (dark ? '#187EAD' : 'black')};
      }
    }
  }
`;

export const Option = styled.label<{ dark: boolean; disabled: boolean; locked: boolean }>`
  margin-top: 10px;
  cursor: ${({ disabled, locked }) => (disabled || locked ? 'not-allowed' : 'pointer')} !important;

  input {
    display: none;
    margin: 0;

    &:checked + span {
      background-color: ${({ disabled }) => (disabled ? 'gray' : COLORS.DarkGreen)};
      color: white;
    }

    &:checked + span > svg {
      display: block;
    }
  }

  span {
    padding: 5px 10px;
    background-color: #f5f5f5;
    border-radius: 10px;
    font-family: 'Baloo 2';
    box-shadow: 0 2px 7px 2px ${({ dark }) => (dark ? 'none' : '#e5e5e5')};
    transition: all 0.2s;
    user-select: none;
    font-size: 1em;
    display: flex;
    align-items: center;
    column-gap: 5px;
    justify-content: space-between;

    &:hover {
      background-color: ${({ disabled }) => (disabled ? '' : COLORS.DarkGreen)};
      color: ${({ disabled }) => (disabled ? '' : 'white')};
      cursor: ${({ disabled, locked }) => (disabled || locked ? 'not-allowed' : 'pointer')};
    }

    svg {
      width: 15px;
      height: 15px;
      opacity: 0.5;
      transition: all 0.2s;
      display: none;
      fill: rgba(0, 0, 0, 0, 6) !important;

      &:hover {
        opacity: 1;
      }
    }
  }
`;

export const InputLabel = styled.div<{ dark: boolean; bold: boolean; disabled: boolean; labelColor: string; locked: boolean }>`
  font-family: 'Roboto', sans-serif;
  font-size: 1.3em;
  margin-bottom: 15px;
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  display: flex;
  align-items: center;
  column-gap: 10px;
  justify-content: flex-start;

  span {
    color: ${({ labelColor }) => labelColor + '!important'};
    align-self: flex-start;
  }

  ${({ locked }) =>
    locked &&
    css`
      span {
        color: ${COLORS.lightGrey} !important;
      }
    `}

  svg {
    align-self: flex-start;
    min-width: 20px;
    min-height: 20px;

    &.share {
      width: 16px;
      height: 16px;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }
`;

export const InputWrapper = styled.div<{ icon?: React.ReactNode }>`
  position: relative;
  display: flex;
  align-items: center;

  input {
    padding: 5px 0 5px ${({ icon }) => (icon ? '30px' : '10px')};
  }

  svg {
    color: #00000030;
    cursor: pointer;
    font-size: 1.5rem;
    position: absolute;
    right: 10px;
  }
`;
export const IconContainer = styled.div`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  display: flex;
  justify-content: center;
`;
