import { MdDone } from 'react-icons/md';
import { PiHourglass } from 'react-icons/pi';
import { TbProgress, TbCircleDashedCheck } from 'react-icons/tb';

// ActionPlan

export const actionsStatus = {
  pending: { label: 'common.status_to_handle', icon: PiHourglass, color: '#DD0615' },
  ongoing: { label: 'common.status_ongoing', icon: TbProgress, color: '#F49D1D' },
  completed: { label: 'common.status_completed', icon: MdDone, color: '#7BDFC8' },
};

export const actionsUrgencyComplexity = {
  low: { label: 'common.low', color: '#7bdfc8', backgroundColor: '#7bdfc833' },
  medium: { label: 'common.medium', color: '#f49d1d', backgroundColor: '#f49d1d33' },
  high: { label: 'common.high', color: '#f97a82', backgroundColor: '#f97a8233' },
};

// Audits/Inventory

export const conformityStatus = {
  non_conforme: { label: 'common.conformity_notConform', color: '#FF7776', backgroundColor: '#FFF0F0' },
  ongoing: { label: 'common.conformity_ongoing', color: '#FDA51D', backgroundColor: '#FFF6E8' },
  conforme: { label: 'common.conformity_conform', color: '#01B593', backgroundColor: '#00C6A11C' },
};

export const auditsStatus = {
  pending: { label: 'common.audits_pending', icon: PiHourglass, color: '#FF746F', backgroundColor: '#FFF1F1', actions: [], actionsToVerify: null },
  ongoing: { label: 'common.audits_ongoing', icon: TbProgress, color: '#F49D1D', backgroundColor: '#F49D1D1A', actions: [], actionsToVerify: null },
  completed: { label: 'common.audits_completed', icon: TbCircleDashedCheck, color: '#3AA3CC', backgroundColor: '#EBF6FD', actions: [], actionsToVerify: [] },
};

// ------------------
