import { useTranslation } from 'react-i18next';

import { Container } from '@components-new/atoms';

import { SearchBar } from '@components-new/molecules';
import { MdOutlineFileDownload } from 'react-icons/md';
import { DownloadView, HeaderTable, StyledInputComponent } from './Inventory_.style';
import { InventoryHeaderProps } from './inventoryHeader.types';

const InventoryHeader = (props: InventoryHeaderProps) => {
  const { searchQuery, setSearchQuery, setDisplayModalDownload, displayModalDownload, selectedFinality, setSelectedFinality, selectedProduct, setSelectedProduct, finalities, products, selectedEntity} = props;

  const { t } = useTranslation();

  const getFinalityOptions = () => {
    return finalities?.map(finality => ({
      label: finality,
      value: finality,
    }));
  };

  return (
    <HeaderTable>
      <Container flex mrChild='15px'>
        <SearchBar placeholder={t('common.search')} query={searchQuery} setQuery={setSearchQuery} colors={{ background: 'white' }} roundedBorder={true} fontFamily={'Nunito'} padding='0 8px'/>
        <StyledInputComponent
          placeholder={t('common.finality')}
          type={'select'}
          options={getFinalityOptions()}
          onChange={(_value: string, _option: any, _context: any, selectedOption: { label: string; value: string }) => {
            setSelectedFinality(selectedOption);
          }}
          value={selectedFinality}
          sharable={false}
          noOptionsMessage={() => t('inventory.no_finality_available')}
          roundedBorder='17px'
          fontFamily='Nunito'
          clearable='true'
          inputFontSize='1.2rem'
        />
        {selectedEntity && 
          <StyledInputComponent
          placeholder={t('common.product')}
          type={'select'}
          options={products}
          onChange={(_value: string, _option: any, _context: any, selectedOption: { label: string; value: string }) => {
            setSelectedProduct(selectedOption);
          }}
          value={selectedProduct}
          sharable={false}
          noOptionsMessage={() => t('inventory.no_product_available')}
          roundedBorder='17px'
          fontFamily='Nunito'
          clearable='true'
          inputFontSize='1.2rem'
        />
        }
      </Container>
      <DownloadView active={displayModalDownload} onClick={() => setDisplayModalDownload(true)}><MdOutlineFileDownload color={'#3B4B5F'} size={20} /><span>{t('inventory.downloadView')}</span></DownloadView>
    </HeaderTable>
  );
};


export default InventoryHeader;
