import { TabCount, Tab, TabContainer, TabUnderline } from "./Tabs_.style";

interface TabsProps {
  views: {
    label: string;
    value: string;
    onClick: () => void;
    count?: number;
  }[];
  selectedView: string;
  tabsUnderlined?: boolean
}

/**
 * Renders a tabbed interface based on the provided views and highlights the selected tab.
 * @param {Object[]} views - An array of objects representing each tab with properties label, value, onClick, and optionally count.
 * @param {string} selectedView - The value of the currently selected tab.
 * @returns {JSX.Element} A container with multiple tabs, each styled based on selection state and optionally displaying a count.
 */

const Tabs = ({ views, selectedView, tabsUnderlined }: TabsProps) => {
  const TabComponent = tabsUnderlined ? TabUnderline : Tab;
  return <TabContainer data-testid='tabs-container' tabsUnderlined={tabsUnderlined}>
    {views.map((view, index) => {
      return (
        <TabComponent key={index} selected={view.value === selectedView} onClick={view.onClick} data-testid={`tabs-element-container-${index}`}>
          {view?.count > 0 && <TabCount selected={view.value === selectedView} data-testid={`tabs-counter-${index}`}>{view.count}</TabCount>}
          <span data-testid={`tabs-label-${index}`} > {view.label}</span>
        </TabComponent >
      );
    })}
  </TabContainer >
}

export default Tabs