import styled, { css } from 'styled-components';
import { IconType } from 'react-icons';

import { BUTTON_COLORS, COLORS } from '../utils/constants';

export interface ButtonProps {
  className?: string;
  label: string;
  type?: 'danger' | 'admin' | 'success' | 'light' | 'bright' | undefined;
  //TODO: change light by outlined everywhere
  outlined?: boolean;
  icon?: IconType;
  iconColor?: string;
  onClick?: any;
  name?: string;
  style?: any;
  disabled?: boolean;
  customColor?: string;
  iconSize?: number;
  lightColor?: string;
  labelOptions?: {
    fontSize?: string;
    fontWeight?: string;
  };
  borderOptions?: {
    width?: string;
    color?: string;
    type?: string;
  };
  shadowOptions?: {
    value?: string;
    active?: boolean;
    hoverValue?: string;
  };
  fontFamily?: string;
  padding?: string;
}

export const Button = ({
  className,
  label,
  type,
  icon,
  iconColor,
  onClick,
  name,
  style,
  disabled,
  customColor,
  iconSize,
  lightColor,
  labelOptions,
  borderOptions,
  shadowOptions,
  fontFamily,
  padding
}: ButtonProps) => {
  let Icon = icon;
  return (
    <ButtonContainer
      className={className}
      disabled={disabled}
      style={{ ...style }}
      // name={name}
      type={type}
      onClick={e => !disabled && onClick(e)}
      customColor={customColor}
      lightColor={lightColor}
      iconColor={iconColor}
      labelOptions={labelOptions}
      borderOptions={borderOptions}
      shadowOptions={shadowOptions}
      fontFamily={fontFamily}
      padding={padding}>
      {icon && <Icon size={iconSize || 20} />}
      <span>{label}</span>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div<{
  type: string;
  disabled?: boolean;
  customColor?: string;
  lightColor?: string;
  iconColor?: string;
  labelOptions?: {
    fontSize?: string;
    fontWeight?: string;
  };
  borderOptions?: {
    width?: string;
    color?: string;
    type?: string;
  };
  shadowOptions?: {
    value?: string;
    active?: boolean;
    hoverValue?: string;
  };
  fontFamily: string,
  padding: string
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => (padding ? padding : '10px 20px')};
  column-gap: 5px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border-radius: 50px;
  transition: all 0.2s ease-in-out;
  width: fit-content;
  height: fit-content;
  text-transform: uppercase;
  font-family: ${({ fontFamily }) => (fontFamily ? `'${fontFamily}'` : `'Roboto', serif`)};
  font-weight: ${({ type }) => (type === 'light' ? 700 : 500)};
  ${({ type, shadowOptions }) =>
    (type !== 'light' || shadowOptions?.active) && `box-shadow: ${shadowOptions?.value ?? '2px 1px 2px rgb(0 0 0 / 14%)'};`};
  ${({ shadowOptions }) =>
    shadowOptions &&
    css`
      &:hover {
        ${shadowOptions.hoverValue ?? 'box-shadow: 2px 4px 6px 0px rgba(244, 157, 29, 0.23);'}
      }
    `};
  letter-spacing: 1.25px;
  white-space: nowrap;
  font-size: 16px;
  position: relative;
  user-select: none;

  border: ${({ type, borderOptions }) =>
    type === 'bright'
      ? `${borderOptions?.width ?? '1'}px ${borderOptions?.type ?? 'solid'} ${borderOptions?.color ?? COLORS.Squash}`
      : `1px solid transparent`};

  background-color: ${({ type, disabled, customColor }) => {
    if (!disabled) {
      switch (type) {
        case 'danger':
          return BUTTON_COLORS.danger;
        case 'admin':
          return BUTTON_COLORS.admin;
        case 'success':
          return BUTTON_COLORS.success;
        case 'light':
          return 'none';
        case 'bright':
          return BUTTON_COLORS.bright;
        default:
          if (customColor) {
            return customColor;
          }
          return BUTTON_COLORS.default;
      }
    } else {
      return BUTTON_COLORS.disabled;
    }
  }};

  > span {
    color: ${({ type, lightColor, disabled }) =>
      disabled
        ? 'rgba(0, 0, 0, 0.6)'
        : type === 'light'
          ? lightColor
            ? lightColor
            : 'rgba(0, 0, 0, 0.6)'
          : type === 'bright'
            ? COLORS.Squash
            : 'white'};
    font-weight: ${({ labelOptions }) => (labelOptions?.fontWeight ? labelOptions.fontWeight : '500')};
    font-size: ${({ labelOptions }) => (labelOptions?.fontSize ? labelOptions.fontSize : '0.9em')};
    transition: color 0.2s ease-in-out;
  }

  ${({ disabled }) =>
    !disabled &&
    `
      &:hover {
        background-color: ${({ type }) => {
          switch (type) {
            case 'danger':
              return BUTTON_COLORS.dangerHover;
            case 'admin':
              return BUTTON_COLORS.adminHover;
            case 'success':
              return BUTTON_COLORS.successHover;
            case 'light':
              return 'none';
            default:
              return BUTTON_COLORS.defaultHover;
          }
        }};
      }
    `};

  svg {
    color: ${({ type, iconColor }) => (iconColor ? iconColor : type === 'light' ? 'black' : 'white')};
    transition: all 0.2s ease-in-out;
  }

  ${({ type }) =>
    type === 'light' &&
    css`
      &:hover {
        border-radius: 50px;
        border: 1px solid rgba(0, 0, 0, 0.5);

        &:before {
          opacity: 1;
        }

        > span {
          color: rgba(0, 0, 0, 0.8);
        }

        svg {
          opacity: 1;
        }
      }
    `};
`;
