import styled from 'styled-components';
import { InputComponent } from '@components/form/InputComponent';

export const StyledInputComponent = styled(InputComponent)`
  width: 37rem;
  background: white;
  border-radius: 17px;
  .input input {
    height: 100% !important;
  }
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000070;
`;