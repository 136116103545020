import styled, { css } from 'styled-components';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import { HiOutlineLink } from 'react-icons/hi';

import { COLORS } from '../../utils/constants';
import { MoreOptionItem, MoreOptions } from '../more-option/MoreOption';
import { useTranslation } from 'react-i18next';

export interface FillingInfos {
  total: number;
  filled: number;
}

export interface SharedItemProps {
  type: string;
  value?: string;
  entityName?: string;
  surveyName?: string;
  stepName?: string;
  versionName?: string;
  options?: Array<MoreOptionItem>;
  questionValue?: string;
  valid?: boolean;
  filling?: FillingInfos;
  onClickFn?: () => void;
  linked?: boolean;
  actionStatus?: string;
  cursor?: string | null;
  backgroundItems?: string;
}

export const SharedItem = ({
  type,
  value,
  entityName,
  surveyName,
  stepName,
  versionName,
  options,
  questionValue,
  valid,
  filling,
  onClickFn,
  linked = false,
  actionStatus,
  cursor = 'pointer',
  backgroundItems
}: SharedItemProps) => {
  const { t } = useTranslation();

  const getBreadCrumb = () => {
    let breadCrumb = '';
    if (entityName) {
      breadCrumb += entityName;
    }
    if (surveyName) {
      breadCrumb += ' - ' + surveyName;
    }
    if (versionName) {
      breadCrumb += ' - ' + versionName;
    }
    if (stepName) {
      breadCrumb += ' - ' + stepName;
    }
    return breadCrumb;
  };

  return (
    <SharedItemContainer linked={linked} onClick={onClickFn} cursor={cursor} backgroundItems={backgroundItems}>
      <ShareItemContent>
        <Title>
          <span>{getBreadCrumb()}</span>
          {value}
        </Title>
        <SharedItemName valid={valid}>
          {valid ? <MdCheckCircle /> : <MdCancel />}
          {type === 'questions' && <span>{valid ? questionValue : t('collaborators.shared_items.no_answer_yet')}</span>}
          {type === 'steps' && (
            <span>
              {filling?.filled}/{filling?.total} {t('collaborators.shared_items.questions_filled')}
            </span>
          )}
          {type === 'entities' && (
            <span>
              {filling?.filled}/{filling?.total} {t('collaborators.shared_items.surveys_completed')}
            </span>
          )}
          {type === 'surveys' && (
            <span>
              {filling?.filled}/{filling?.total} {t('collaborators.shared_items.completed_steps')}
            </span>
          )}
          {type === 'actions' && <span>{actionStatus}</span>}
        </SharedItemName>
      </ShareItemContent>
      {linked && <HiOutlineLink />}
      {options?.length > 0 && <MoreOptions options={options} />}
    </SharedItemContainer>
  );
};

const Title = styled.div`
  font-size: 1.2rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 5px;

  span {
    font-weight: 400;
    color: ${COLORS.MediumGrey};
    font-size: 1.1rem;
    font-family: 'Roboto', sans-serif;
  }
`;

const ShareItemContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: 5px;

  span {
    font-weight: 400;
  }
`;

const SharedItemContainer = styled.div<{ linked: boolean; cursor: string, backgroundItems: string }>`
  display: flex;
  align-items: center;
  background: ${({ backgroundItems }) => backgroundItems};
  padding: 15px;
  border-radius: 15px;
  border: 1px ${({ linked }) => (linked ? 'dashed' : 'solid')} ${COLORS.LightGrey};
  column-gap: 15px;
  cursor: ${({ cursor }) => cursor};
  transition: border 0.2s;

  > svg {
    font-size: 18px;
    color: lightgray;
  }
  ${({ linked }) =>
    !linked &&
    css`
      &:hover {
        border: 1px solid ${COLORS.MediumGrey};
      }
    `}
`;

const SharedItemName = styled.div<{ valid: boolean }>`
  font-family: 'Roboto', sans-serif;
  font-size: 1.1rem;
  color: ${COLORS.Grey};
  display: flex;
  align-items: center;
  column-gap: 5px;

  svg {
    min-width: 20px;
    min-height: 20px;
    color: ${({ valid }) => (valid ? COLORS.AquaMarine : COLORS.MediumGrey)};
  }

  span {
    color: ${({ valid }) => (valid ? COLORS.Grey : COLORS.MediumGrey)};
  }
`;
