import { InputComponent } from '@components/form/InputComponent';
import styled from 'styled-components';
export const NewSubContractor = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: 'Nunito';
  color: #197EAD;
  position: absolute;
  right: calc(3rem + 20px);
  font-weight: 700;
  font-size: 1.4em;
  span {
    margin-left: 10px;
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }
`
export const ActionsContainer = styled.div`
  position: relative;
  border-radius: 25px;
  padding: 15px;
  border: 1px solid rgba(196, 195, 194, 0.36);
  width: 31%;
  > :not(:last-child) {
    margin-bottom: 10px;
  }
  font-size: 1.1rem;
`;

export const ContainerTitle = styled.div<{color: string, backgroundColor: string}>`
  display: flex;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  align-items: center;
  padding: 5px;
  span {
    font-weight: bold;
    font-size: 1.4rem;
    font-family: 'Nunito'
  }
  border-radius:20px;
  font-weight: 600;
  margin-left: 8px;
`;


export const List = styled.div`
  > :not(:last-child) {
    margin-bottom: 10px;
  };
  height: 87%;
  position: absolute;
  width: 95%;
  overflow: auto;
`;

export const ListItem = styled.div<{ active: boolean, status: string }>`
  padding: 15px;
  border-radius: 17px;
  justify-content: space-between;
  align-items: center;
  margin: 5px 10px;
  box-shadow: 0px 3px 6px 0px rgba(196, 195, 194, 0.20);
  height:auto;
  border: 1px solid #E5E5E5;
  ${({ active }) => {
    return active ? 'border: 1px solid rgba(255, 116, 111, 0.24);' : 'border: 1px solid #E5E5E5;';
  }};
  cursor : pointer;
`;

export const SubContractorName = styled.div`
  color: #3D3D3D;
  font-size: 1.2em;
  font-weight: 600;
`;

export const NoSubContractor = styled.div`
  font-size: 1.1em;
  color: #757575;
  margin: 5px 0;
`;

export const DowngradeButton = styled.div`
  color: #FF7675;
  border-radius: 20px;
  border: 1px solid #FF7675;
  background: #FFF4F4;
  width: fit-content;
  padding: 10px;
  display: flex;
  align-items: center;
  span {
    font-weight: 700;
    margin-right: 4px;
    margin-top: 2px;
  }
`;

export const Link = styled.a`
  display: flex;
  color: #757575;
  text-decoration: underline;
  font-weight: 700;
  font-size: 1em;
  span {
    display: flex;
    margin-left: 5px;
  }
`

export const BackgroundResponse = styled.div<{display:string}>`
  display: ${({ display }) => display};
  background: rgba(221, 241, 249, 0.40);
  height: 80%;
  border-radius: 15px;
  grid-template-rows: max-content;
  align-content: flex-start;
`

export const ResponseContainer = styled.div`
  overflow-y: auto;
  padding-right: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  border-radius: 15px;
  padding: 10px;
`;

export const ResponseHeader = styled.div`
display: flex;
justify-content: space-between;
padding: 20px;
align-items: center;
a {
  cursor: pointer;
  color: #326C82;
  font-weight: 800;
  font-size: 1.4em;
  font-family: 'Nunito';
  text-decoration: underline;
  text-decoration-thickness: 1px;
}
height: fit-content;
`

export const TitleModal = styled.span`
  color: rgba(0, 0, 0, 0.80);
  font-size: 2em;
  font-weight: 700;
  font-family: 'Nunito';
  margin-left: 8px;
`
export const ContainerSubTitleModal = styled.div`
  display: flex;
  font-size: 1.2em;
  justify-content: space-between;
  margin-left: 8px;
  margin-right: 13px;
`

export const ContactInfoModal = styled.span`
  display: flex;
  align-items: center;
  font-size: 1.2em;
  font-weight: 600;
  color: #3D3D3D;
  span {
    margin-left: 6px;
    font-family: 'Nunito'
  }
`

export const StatusModal = styled.span<{color: string, backgroundColor: string}>`
  display: flex;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  align-items: center;
  border-radius:20px;
  span { 
    margin-left: 0;
    margin-right: 10px;
  }
  font-size: 1.2em;
`;

export const Span = styled.span`
  font-family: 'Nunito'
`

export const NoAnswers = styled.div`
  font-family: 'Nunito';
  font-size: 1.2em;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 116px;
  font-style: italic;
  span {
    font-style: normal;
    margin-left: 5px;
  }
`

export const Separator = styled.span`
  width: 0.5px;
  height: 16px;
  background: #757575;
  margin: 0 9px;
`
export const LegalOpsElement = styled.span<{fontSize: string, mt?: string}>`
  display: flex;
  align-items: center;
  font-size: ${({ fontSize }) => fontSize};
  color: #978FFF;
  span {
    margin-left: 5px;
    margin-top: ${({ mt }) => mt || 0};
  }
  a {
    color: #978FFF;
    align-self: center;
    display: flex
  }
`

export const NewSubContractorTitle = styled.div`
  font-family: 'Nunito';
  font-size: 2em;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  span {
    margin-left: 10px;
  }
`

export const Label = styled.div`
  color: #3D3D3D;
  font-family: Nunito;
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 13px;
`

export const StyledInputComponent = styled(InputComponent)`
  background: white;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center
`;

export const RemindersTitle = styled.div`
color: rgba(0, 0, 0, 0.80);
font-family: Nunito;
font-size: 1.8em;
font-weight: 600;
margin-top: 10px;
margin-bottom: 18px;
`

export const RemindersWarning = styled.div`
display: flex;
align-items: center;
color: #326C82;
padding-left: 14px;
line-height: 150%;
font-family: Nunito;
font-size: 1.3em;
font-weight: 500;
gap: 16px;
border-radius: 10px;
border: 1px solid #3AA9D4;
background: rgba(58, 163, 204, 0.10);
padding: 9px 14px;
`

export const RemindersBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 19px;
  padding: 10px 20px;
  border-radius: 10px;
  background: #FFF;
`

export const LabelReminder = styled.div<{fw: string}>`
  color: #3D3D3D;
  font-family: 'Nunito';
  font-size: 1.3em;
  font-weight: ${({ fw }) => fw};
  span {
    color: #326C82;
  }
`

export const ReminderChoice = styled.div`
  font-family: 'Nunito';
  font-size: 1.3em;
  display: flex;
  gap: 25px;
  font-weight: 600;
  color: #3D3D3D;
  label {
    cursor: pointer;
  }
  input {
    margin-right: 8px;
    cursor: pointer;
 }
`

export const ContainerConfirm = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  margin-top: 100px;
  gap: 50px;
  p {
    text-align: center;
    font-size: 1.5em;
    color: 3D3D3D;
    font-family: 'Nunito'
  }
`

export const ContainerConfirmLegalOps = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  p {
    text-align: center;
    font-size: 1.5em;
    color: 3D3D3D;
    font-family: 'Nunito'
  }
`

export const TextReturn = styled.span`
    font-size: 1.2em;
    font-family: 'Nunito';
    color: #326C82;
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer
`

export const AuditsCompletedText = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 95%;
  white-space: nowrap;
  margin: 0 10px;
  margin-top: 15px;
  font-family: 'Nunito';
  font-weight: 600;
  font-size: 1.2em;
  color: #3D3D3D
`

export const SeparatorCompleted = styled.div`
  width: inherit;
  height: 1px;
  background: #757575;
`