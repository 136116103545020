import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from '@components-new/organisms';
import { dataTableColumnsProps } from '@components-new/common/types';
import { ContainerNameBottom, Criteria, DownloadText, DownloadTitle, LastAudit, Link, List, NoData, SubContractorName } from './Inventory_.style';
import { Chip, Container } from '@components-new/atoms';
import { conformityStatus } from '@components-new/common/constants';
import dayjs from 'dayjs';
import { Modal } from '@components/modal/Modal';
import { MdOutlineDoNotDisturb, MdOutlineFileDownload } from 'react-icons/md';
import { Button } from '@components/Button';
import restricted from '../../../../assets/restricted.svg';
import { CSM_MANAGERS, LEGAL_OPS } from '@utils/constants';
import { selectActiveAccount } from 'src/reducers/account.reducer';
import { useSelector } from 'react-redux';

import { downloadAllSubContractorsInventory, downloadFilteredSubContractorsInventory, downloadLegalOfficerClientInventory, fetchAccountSubContractors, fetchAllSubContractors, fetchLegalOfficerClientsSubContractors } from '@api/audit';
import { compareQueryToSubContractorName } from '@utils/utils';
import InventoryHeader from './Header';
import { createAndSaveInventoryBlob, sortSubcontractorsByStatus } from '../AuditsAndInventory.utils';
import { InventoryResponse } from '../AuditsAndInventory.types';
import Loader from '@components/loader/loader';
import { toast } from 'react-toastify';
import { IoLinkOutline } from 'react-icons/io5';
import { SiTicktick } from "react-icons/si";
import ModalAudit from '../components/ModalAudit';
import Div from '@components/div/Div';
import useDidUpdate from '@hooks/useDidUpdate';
import { fetchContentfulEntryById, fetchSubContractorResponses, validateOrRefuseAuditLegalOps } from '@utils/api';
import { ST_ENTRY_ID } from '../audit/Audit.constants';
import { ContainerConfirmLegalOps } from '../audit/Audit_.style';
import { RxCross2 } from 'react-icons/rx';
import { TbCircleDashedCheck } from 'react-icons/tb';

const Inventory = ({ currentAccount, selectedEntity, actionPlanActived }) => {
  const { t } = useTranslation();

  const activeAccount = useSelector(selectActiveAccount);

  const [inventory, setInventory] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState<string>(null);
  const [selectedFinality, setSelectedFinality] = useState<any>(null);
  const [selectedFinalityModal, setSelectedFinalityModal] = useState<any>(null);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [displayModalDownload, setDisplayModalDownload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
  const [selectedSubContractor, setSelectedSubContractor] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [subContractorData, setSubContractorData] = useState(null);
  const [surveyData, setSurveyData] = useState(null);
  const [emailReminder, setEmailReminder] = useState(null);
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [selectedType, setSelectedType] = useState(null);


  const clientColumns: dataTableColumnsProps[] = [
    {
      field: 'subContractorName',
      headerName: t('common.subContractor'),
      width: LEGAL_OPS.includes(activeAccount._id) ? 'col-1-25' : 'col-1-75',
      bolder: true,
      center: false,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'finalityCol',
      headerName: t('common.finality'),
      width: LEGAL_OPS.includes(activeAccount._id) ? 'col-1-25' : 'col-1-5',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'servers',
      headerName: t('common.servers'),
      width: 'col-1-25',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'dpa',
      headerName: t('common.DPA'),
      width: 'col-1-25',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'nonUETransfer',
      headerName: t('common.transfersOutsideUE'),
      width: 'col-1-25',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'cct',
      headerName: t('common.warranties'),
      width: 'col-1-25',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'requiredAction',
      headerName: t('common.requiredAction'),
      width: 'col-1-25',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'status',
      headerName: t('common.conformity'),
      width: 'col-1-25',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    LEGAL_OPS.includes(activeAccount._id) && {
      field: 'legalOpsActions',
      headerName: 'Actions',
      width: 'col-1-25',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
  ];

  // Fetch Subcontractor survey data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchContentfulEntryById(ST_ENTRY_ID);
        setSurveyData(result);
      } catch (err) {
        console.log("🚀 ~ err:", err)
      }
    };
    fetchData();
  }, []);

  const fetchAndSetInventory = async () => {
    let response;
    setLoading(true)

    if (currentAccount._id === 'all') {
      response = await fetchAllSubContractors();
    } else if (currentAccount._id === 'myClients') {
      response = await fetchLegalOfficerClientsSubContractors(activeAccount.users[0].email);
    } else {
      response = await fetchAccountSubContractors(currentAccount?._id);
    }

    if (response?.success) {
      const sortedData = sortSubcontractorsByStatus(response.data);
      setInventory(sortedData);
    }
    else {
      toast.error(t('inventory.error'))
      console.error(response.error)
    }

    setLoading(false)
  };


  useEffect(() => {
    fetchAndSetInventory();
  }, [currentAccount?._id, activeAccount?.users[0]?.email]);

  useDidUpdate(async () => {
    const subContractorId = selectedSubContractor?.subContractor?.id;
    if (!subContractorId) {
      setSubContractorData(null);
      return;
    }

    try {
      const result = await fetchSubContractorResponses(subContractorId);

      if (result && Object.keys(result).length > 0) {
        setSubContractorData(result);
        setEmailReminder(result?.responsableEmail || selectedSubContractor?.subContractor?.contactEmail);
      }
      else {
        setSubContractorData(null);
      }
    } catch (error) {
      console.error("Error fetching subcontractor responses:", error);
      setSubContractorData(null);
    }
  }, [selectedSubContractor]);

  const handleValidateOrRefuseAudit = ((type, subContractor) => {
    setSelectedSubContractor(subContractor)
    setSelectedType(type)
    setDisplayConfirmModal(true)
  })

  const validateOrRefuseAudit = async (type, subContractor) => {
    await validateOrRefuseAuditLegalOps(type, subContractor._id)
    fetchAndSetInventory()
    setDisplayConfirmModal(false)
  }

  const rawClientRows = useMemo(() => {
    return inventory
      ?.map((subContractor) => {
        return {
          ...subContractor,
          subContractorName:
            () => {
              return (
                <div>
                  <SubContractorName>{subContractor.companyName || subContractor.clientGivenName}</SubContractorName>
                  <ContainerNameBottom>
                    <div>{subContractor.responsableEmail}</div>
                    {subContractor.lastAuditDate && <LastAudit>{t('inventory.lastAudit')} : {dayjs(subContractor.lastAuditDate).format('DD/MM/YYYY')}</LastAudit>}
                  </ContainerNameBottom>
                </div>)
            },
          finalityCol:
            () => {
              if (subContractor.status !== 'ongoing') {
                return subContractor.finality
              } else {
                return <Chip label={t("inventory.ongoingAudit")} color={'#3D3D3D'} backgroundColor={'#F2F2F5'} width='100%' justifyContent='center' fontSize='1.2rem' />;
              }
            },
          servers: () => {
            if (subContractor.status !== 'ongoing') {
              return <List>{subContractor.serversLocation.map((location) => {
                return (
                  <li>{location}</li>
                )
              })}</List>
            }
          },
          dpa: () => {
            if (subContractor.status !== 'ongoing') {
              return (
                subContractor.dpa.length ? <div>{subContractor.dpa[0].text || subContractor.dpa[0]} <br /> {subContractor.dpa[0].link && typeof subContractor.dpa[0].link === 'string' && <Link href={subContractor.dpa[0].link}>{t('inventory.accessibleHere')}</Link>}</div>
                  : <div>{t('inventory.missingDPA')}</div>
              )
            }
          },
          nonUETransfer: () => {
            if (subContractor.status !== 'ongoing') {
              return (
                subContractor.nonUETransfer.length ? <List>{subContractor.nonUETransfer.map((location) => {
                  return (
                    <li>{location}</li>
                  )
                })}</List>
                  : <div>{t('common.no')}</div>
              )
            }
          },
          cct: () => {
            if (subContractor.status !== 'ongoing') {
              return (
                <List>
                  {subContractor.cct.map((action) => {
                    if (typeof action === 'string') {
                      return <li>{action}</li>
                    } else {
                      return (
                        <li><span>{action.text}</span><br /><Link href={action.link}>{t('inventory.accessibleHere')}</Link></li>
                      )
                    }
                  })}
                </List>
              )
            }
          },
          requiredAction: () => {
            if (subContractor.status !== 'ongoing') {
              return (
                <List>
                  {subContractor.requiredAction.map((action) => {
                    return (
                      <li><span>{action.text}</span><br /><Link href={action.link}>{t('inventory.accessibleHere')}</Link></li>
                    )
                  })}
                </List>
              )
            }
          },
          status: () => {
            if (subContractor?.status !== 'ongoing') {
              const { label, color } = conformityStatus[subContractor.status];
              return <Chip label={t(label)} color={color} fontSize={'1.3rem'} />;
            }
          },
          legalOpsActions: () => {
              return (
              <Div flex alignItems='center' gap='10px'>
                <IoLinkOutline onClick={() => openModalAudit(subContractor)} size={'25px'} color='#978FFF' cursor={'pointer'}/>
                {subContractor.auditCompleted &&
                  <>
                    <SiTicktick onClick={() => handleValidateOrRefuseAudit('validate', subContractor)} size={'18px'} color='#978FFF' cursor={'pointer'}/>
                    <MdOutlineDoNotDisturb onClick={() => handleValidateOrRefuseAudit('refuse', subContractor)} size={'20px'} color='#978FFF' cursor={'pointer'}  />
                  </> 
                }
              </Div>
              )
          },
          customSizeCols: subContractor.status === 'ongoing' && (LEGAL_OPS.includes(activeAccount._id) ? ['col-1-5', 'col-9', '', '', '', '', '', '', 'col-1-25'] : ['col-1-75', 'col-10'])
        };
      });
  }, [inventory]);

  const finalitiesList: string[] = useMemo(() => {
    if (!rawClientRows?.length) return [];

    const finalities: string[] = rawClientRows
      .map(row => row.finality)
      .filter((finality): finality is string => Boolean(finality));

    return [...new Set(finalities)];
  }, [rawClientRows]);

  const productsList = useMemo(() => {
    const products = [];

    products.push({
      label: selectedEntity?.name,
      value: selectedEntity?._id,
    })

    setSelectedProduct(null)
    rawClientRows?.map(row => {
      selectedEntity?.responses.map(response => {
        let version = response.versions
          .find(version => {
            return version._id === row.relatedVersionId;
          })
        if(version){
          products.push({
            label: version?.versionName,
            value: version?._id,
            version: version?._id
          })
        }
      });
    });

    const uniqueProducts = Array.from(
      new Map(products.map(item => [item.value, item])).values()
    );

    return uniqueProducts;
  }, [rawClientRows, selectedEntity]);

  const clientRows = useMemo(() => {
    if (!rawClientRows) return [];

    return rawClientRows
      .filter(elt => {
        return selectedEntity ? elt.initiatorEntity === selectedEntity._id.toString() : true;
      })
      .filter(elt => {
        return compareQueryToSubContractorName(elt.companyName || elt.clientGivenName, searchQuery);
      }).filter(elt => {
        return selectedFinality ? elt.finality === selectedFinality.label : true;
      })
      .filter(elt => {
        if(selectedEntity && selectedProduct){
          if(selectedProduct?.version){
            return elt.relatedVersionId === selectedProduct.value;
          } else if (selectedProduct.value === selectedEntity._id && !elt.relatedVersionId) {
            return true;
          } else {
            return false
          }
        }
        return true
      })
  }, [rawClientRows, searchQuery, selectedFinality, selectedEntity, selectedProduct]);

  const handleDownloadInventory = async () => {
    try {
      setLoadingDownload(true)
      let response: InventoryResponse;
      let fileName = 'Inventaire_SousTraitants_';
      switch (currentAccount._id) {
        case 'all':
          fileName += '_AllClients_';
          response = await downloadAllSubContractorsInventory(selectedFinality?.label);
          break;

        case 'myClients':
          fileName += '_MyClients_';
          const legalOfficerEmail = activeAccount.users[0].email;
          response = await downloadLegalOfficerClientInventory(legalOfficerEmail, selectedFinality?.label);
          break;

        default:
          fileName += '_';
          response = await downloadFilteredSubContractorsInventory(
            currentAccount._id,
            selectedEntity?._id,
            selectedFinality?.label,
            selectedProduct?.value
          );
      }

      if (!response.success || !response.data) {
        throw new Error(response.error || 'Failed to download inventory');
      }

      createAndSaveInventoryBlob(response.data.data, fileName);

      setDisplayModalDownload(false)
    } catch (error) {
      toast.error(t('inventory.downloadError'))
    } finally {
      setLoadingDownload(false)
    }
  };

  const showInventoryDownloadModal = useMemo(() => {
    if (CSM_MANAGERS.includes(activeAccount._id.toString()) || currentAccount._id !== 'all') {
      return true;
    }
    return false;
  }, [activeAccount._id, currentAccount._id]);

  if (loading) {
    return (
      <Container flex justifyContent='center' mt='200px'>
        <Loader loaderOnly={true} />
      </Container>
    )
  }

  const fetchSubContractorData = async(subContractor) => {
    const subContractorId = subContractor?._id;
    if (!subContractorId) {
      setSubContractorData(null);
      return;
    }

    try {
      const result = await fetchSubContractorResponses(subContractorId);
      if (result && Object.keys(result).length > 0) {
        setSubContractorData(result);
        setEmailReminder(result?.responsableEmail || subContractor?.contactEmail);
      }
      else {
        setSubContractorData(null);
      }
    } catch (error) {
      console.error("Error fetching subcontractor responses:", error);
      setSubContractorData(null);
    }
  }

  const openModalAudit = ((subContractor) => {
    setSelectedSubContractor(subContractor)
    fetchSubContractorData(subContractor)
    setDisplayModal(true)
    setSelectedFinalityModal({ label: subContractor.finality, value: subContractor.finality })
  })

  const handleCloseConfirmModal = () => {
    setDisplayConfirmModal(false);
  };
  
  return (
    <>
      {
        !clientRows.length && !searchQuery && !selectedFinality && !selectedProduct ?
          <NoData dangerouslySetInnerHTML={{
            __html: t('inventory.noData', { account: currentAccount?.companyName }),
          }}></NoData> :
          <DataTable
            loading={false}
            rows={clientRows}
            columns={clientColumns}
            tableInventory={true}
            noDataText={t('inventory.noDataSearch')}
            customHeader={
              <InventoryHeader
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                displayModalDownload={displayModalDownload}
                setDisplayModalDownload={setDisplayModalDownload}
                selectedFinality={selectedFinality}
                setSelectedFinality={setSelectedFinality}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                finalities={finalitiesList}
                products={productsList}
                selectedEntity={selectedEntity}
              />
            }
            searchPlaceholder={t('actionPlan.search_actions')}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
      }
      {showInventoryDownloadModal ? (
        <Modal
          size={{ width: '630px' }}
          top="25%"
          rowGap="0"
          show={displayModalDownload}
          onClose={() => setDisplayModalDownload(false)}
          closeColor="#00000057"
        >
          {loadingDownload ? (
            <Container flex justifyContent='center' mt='100px' mb='100px'>
              <Loader loaderOnly={true} />
            </Container>
          ) : (
            <>
              <DownloadTitle>
                <MdOutlineFileDownload color={'#3B4B5F'} size={22} />
                <span>{t('common.downloading')} :</span>
              </DownloadTitle>
              <DownloadText
                dangerouslySetInnerHTML={{
                  __html: t('inventory.downloadText'),
                }}
              />
              <Criteria>
                <p>
                  {t('common.client')} :{' '}
                  {currentAccount?._id === 'all'
                    ? t('common.allClients')
                    : currentAccount?._id === 'myClients'
                      ? t('common.myClients')
                      : currentAccount?.companyName}
                </p>
                {selectedEntity && (
                  <p>
                    {t('common.entity')} : {selectedEntity.name}
                  </p>
                )}
                {selectedFinality && (
                  <p>
                    {t('common.finality')} : {selectedFinality.label}
                  </p>
                )}
                {selectedProduct && (
                  <p>
                    {t('common.product')} : {selectedProduct.label}
                  </p>
                )}
              </Criteria>
              <Container alignSelf="center" mt="34px">
                <Button
                  customColor="#326C82"
                  label={t('common.confirmDownload')}
                  onClick={handleDownloadInventory}
                />
              </Container>
            </>
          )}
        </Modal>
      ) : (
        <Modal
          size={{ width: '680px' }}
          top="25%"
          rowGap="0"
          show={displayModalDownload}
          onClose={() => setDisplayModalDownload(false)}
          closeColor="#00000057">
          <DownloadTitle>
            <img src={restricted} alt="restricted" />
            <span>{t('inventory.restrictedTitle')}</span>
          </DownloadTitle>
          <Criteria>
            <p>{t('inventory.restrictedP1')}</p>
            <p
              dangerouslySetInnerHTML={{
                __html: t('inventory.restrictedP2'),
              }}
            />
          </Criteria>
        </Modal>
      )}

      <ModalAudit
        view={'inventory'}
        selectedEntity={selectedEntity}
        selectedFinality={selectedFinalityModal}
        setSelectedFinality={setSelectedFinalityModal}
        selectedSubContractorOrAction={selectedSubContractor}
        activeAccount={activeAccount}
        currentAccount={currentAccount}
        displayModal={displayModal}
        setDisplayModal={setDisplayModal}
        subContractorData={subContractorData}
        setSubContractorData={setSubContractorData}
        surveyData={surveyData}
        emailReminder={emailReminder}
        setEmailReminder={setEmailReminder}
        fetchAudits={null}
        setLoading={setLoading}
      />

      <Modal show={displayConfirmModal} title={t('inventory.confirm_title')} onClose={handleCloseConfirmModal}>
          <ContainerConfirmLegalOps>
            <p dangerouslySetInnerHTML={{
              __html: selectedType === 'validate' ? 
              t('audits.confirmAudit', { name: selectedSubContractor?.companyName || selectedSubContractor?.clientGivenName }) : 
              t('audits.refuseAudit', { name: selectedSubContractor?.companyName || selectedSubContractor?.clientGivenName }),
            }} />
            <Container flex justifyContent='center' mt='13px' width='100%'>
              <Container flex column alignItems='center' width='100%'>
                <Button style={{ 'width': '25%' }} fontFamily='Nunito' 
                label={t(`common.${selectedType}`)} icon={selectedType === 'validate' ? TbCircleDashedCheck : RxCross2} 
                customColor={selectedType === 'validate' ? '#326C82' : '#FF746F'} padding='7px 25px' labelOptions={{ fontSize: '1em' }} 
                onClick={() => { validateOrRefuseAudit(selectedType, selectedSubContractor) }} />
              </Container>
            </Container>
          </ContainerConfirmLegalOps>
        </Modal>
    </>
  )
};

export default Inventory;


